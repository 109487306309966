import { Button } from "@mui/material";
import {  useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

import styles from './UploadImages.module.scss';

interface UploadImagesProps {
  portfolioId: string;
}

interface PreviewFile {
  file: File,
  preview: string 
  existing: boolean;
}

interface ImageId {
  id: string;
  portfolio: string;
}

function UploadImages(props: UploadImagesProps) {
  console.log("UploadImages: portfolioId", props.portfolioId);
  const uploadUrl = `/api/v1/portfolio/${props.portfolioId}/image`;

  const [files, setFiles] = useState<PreviewFile[]>([]);

  const getImages = () => {
    const urlImages: string[] = [];
    fetch(uploadUrl,
      { method: 'GET' })
      .then(res => res.json())
      .then(data => {
        console.log("data", data);
        data.map((id: ImageId) => {
          urlImages.push(id.id);
        });
        console.log("urls:", urlImages);
        // download images
        setFiles(
          urlImages.map((id: string) => ({
            file: new File([], ""),
            existing: true,
            preview: id
          }))
        );
      });
  }

  // When a portfolio is selected, get the list of images.
  useEffect(() => {
    getImages();
  }, [props.portfolioId])

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {'image/*': ['.jpeg', '.png']},
    onDrop: acceptedFiles => {
      setFiles([
        ...files, 
        ...acceptedFiles.map(file => ({
          file,
          existing: false,
          preview: URL.createObjectURL(file),
        }))
      ]);
    }
  });

  const onFileDelete = (ev: React.MouseEvent<HTMLSpanElement, MouseEvent>, file: PreviewFile) => {
    ev.stopPropagation();
    if (file.existing ) {
     // send delete to server
      fetch( `/api/v1/portfolio/${props.portfolioId}/image/${file.preview}`, {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: file.preview
          })
        })
        .then()
        .catch((err) => alert(`Kon foto ${file.preview} niet verwijderen, error ${err}`));
    }
    setFiles(files.filter(f => f !== file));
  }

  const onSubmit = () => {
    let promises = [];
    for(const f of files) {
      if ( !f.existing ) {
        const formData = new FormData();
        formData.append("files", f.file);
        promises.push(fetch(uploadUrl, {
          method: 'POST',
          body: formData,
        }).then(r => { if (r.status !== 201) throw new Error(`${JSON.stringify(r)}`) })
        )
      }
    }
    if(promises.length === 0) return;
    Promise.all(promises)
      .then(r => {
        alert('Upload geslaagd!');
        getImages();
      })
      .catch(err => {
        console.log({err});
        alert(`er ging iets mis bij het uploaden: ${err}`);
      })
  }

  const getUrl = (file: PreviewFile): string => {
    if (file.existing) {
      return `images/${file.preview}.jpeg`;
    } else {
      return file.preview;
    }
  }

  files.map( f => { console.log( f.preview, f.existing)});  

  const thumbs = files.map(file => (
    <div key={file.preview} className={styles.preview}>
      <span className={styles.close} onClick={ev => onFileDelete(ev, file)}>X</span>
      { file.existing  && <span className={styles.title}>huidig</span>  }
      <img src={getUrl(file)} alt=""/>
    </div>
  ));
  
  return (<>
    <div className={`${styles.container} ${isDragAccept ? styles.accept : ''} ${isDragReject ? styles.reject : ''}`} {...getRootProps()}>
      <input {...getInputProps()} />
      <div>Sleep of klik om bestanden te uploaden.</div>
      {thumbs}
    </div>
    <Button type="submit" variant="outlined" onClick={() => onSubmit()}>Upload</Button>
  </>)
}
export default UploadImages;