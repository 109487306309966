import * as React from 'react';

import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import KDImage from './KDimage';

interface PortfolioImageListProps {
  images: string[];
}
export default function PortfolioImageList(props: PortfolioImageListProps) {
  // Get images from server
  return (
    <ResponsiveMasonry
      columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
    >
      <Masonry gutter='10px'>
        {props.images.map((item) => (<div style={{cursor: "pointer"}}>
              <KDImage
                key={item}
                src={item}
                largeSrc={item}
                maxHeight="500px"
              />
            </div>
        ))}
      </Masonry>
    </ResponsiveMasonry>
  );
}
