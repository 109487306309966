// Context/UserContext
import React, { useState, createContext, useEffect, useCallback } from "react";
import { mainNavigationItems } from "./NavigationItems";
import { PortfolioItem } from './PortfolioItemProps';

interface UserContextProviderProps {
  children: React.ReactNode
}


type MenuType = {
  name: string;
  path?: string;
  dropdown?: Array<MenuType>;
}

type ArrayMenuType = Array<MenuType>;

interface IUserContext {
  loggedIn: boolean;
  setLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  navMenu: ArrayMenuType;
  portfolios: PortfolioItem[];
  refresh: () => void;
}

var portfolioNavigationItems: ArrayMenuType;

const UserContext = createContext<IUserContext>({ loggedIn: false, setLoggedIn: (loggedIn) => { }, navMenu: [], portfolios: [], refresh: () => {} });

const UserContextProvider = ({ children }: UserContextProviderProps) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [navMenu, setNavMenu] = useState<ArrayMenuType>([]);
  const [portfolios, setPortfolios] = useState<PortfolioItem[]>([]);


  const fillNavItems = (data: any) => {
    portfolioNavigationItems = [];
    data?.map((portfolio: { name: any; }) => {
      portfolioNavigationItems.push({ name: portfolio.name, path: '/portfolio', dropdown: undefined });
    });
    const mainNavItem = mainNavigationItems.find(obj => { return obj.name === 'Portfolio'; });
    if (mainNavItem) {
      mainNavItem.dropdown = portfolioNavigationItems;
    }
    return mainNavigationItems;
  }

  const refresh = () => {
    fetch('/api/v1/portfolio',
      { method: 'GET' })
      .then(res => res.json())
      .then(data => {
        console.log(  "data:", data);
        setPortfolios(data);
        setNavMenu(fillNavItems(data))
      })
      .catch((err) => alert('unable to get portfolios: ' + err));
  }

  const fetchLoginStatus = useCallback(() => {
    fetch('/api/v1/session',
      { method: 'GET' })
      .then(res => res.json())
      .then(data => { setLoggedIn(true) })
      .catch((err) => { setLoggedIn(false) });
  }, [setLoggedIn])

  // GFet data and login state once on initialization.
  useEffect(() => {
    refresh();
    fetchLoginStatus();
  }, [fetchLoginStatus]);

  return (
    <UserContext.Provider value={{ loggedIn, setLoggedIn, navMenu, portfolios, refresh }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };

