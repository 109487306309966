import React from 'react';
import '../App.module.scss';
import CSS from 'csstype';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


interface ITextEditor {
    text: string;
    onChange: (value: string) => void;
 }

function TextEditor( props: ITextEditor) {
 
    console.log(props.text);

    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, 'normal'] }],
            [{ script: 'sub' }, { script: 'super' }],
            [
                { color: [] },
                'bold',
                'italic',
                'underline',
                'strike',
                'blockquote',
                'code-block'
            ],
            [
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' },
                { align: [] }
            ],
            ['link', 'video', 'clean']
        ],
        clipboard: {
            matchVisual: false
        }
    };

    const editorStyle: CSS.Properties = {
      textAlign: 'center',
      width: '100%',
      height: '500px',
      marginLeft: 'auto',
      marginRight: 'auto'
      
    }
    
    return ( 
        <div style={editorStyle}>
        <ReactQuill style={editorStyle} onChange={(value) => props.onChange(value)} modules={modules} theme="snow" value={props.text}  placeholder='type hier uw tekst' />
        </div>
    );    
}

export default TextEditor;