import { useState, useEffect } from "react";

import { FormControl, InputLabel, MenuItem, Select, Box, Button } from '@mui/material';
import Grid from '@mui/material/Grid';

import UploadImages from "./UploadImages";
import TextEditor from "./TextEditor";

import NoBorderItem from "../components/NoBorderItem";
import useUserContext from "../components/useUserContext";

//import DropdownSmart from "../components/DropDownSmart";

function FillPortfolio() {
    // Get portfolio items from main navigation menu
    const { portfolios, refresh } = useUserContext();
    const [selected, setSelected] = useState(0);
    const [desc, setDesc] = useState('');

    useEffect(() => {
        setDesc(portfolios[selected].desc);
    }, [selected, portfolios])

    const onSubmit = () => {
        // Update the description on the server
        fetch(`/api/v1/portfolio/${portfolios[selected].id}`, { 
            method: 'PUT', 
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ desc })
        })
        .then((res) => {
            // Refresh the portfolio data.
            refresh();
            alert('Portfolio ' + portfolios[selected].name + ' beschrijving bewaard');
        })
        .catch((err) => alert('Kon portfolio niet wijzigen' + err));
    }
    console.log("Portfolio: ", portfolios[selected].name, portfolios[selected].id);
    return (
        <>
            <Grid container spacing={2}>
            <Grid item style={{border:0}} xs={12} sm={6}>
            <NoBorderItem>
            <FormControl fullWidth>
                <InputLabel id="portfolio-select-label">Portfolio</InputLabel>
                <Select
                    labelId="portfolio-select-label"
                    id="portfolio-select"
                    value={selected}
                    label="Portfolio"
                    onChange={(event) => setSelected(Number(event.target.value))}
                >
                {
                    Object.entries(portfolios).map(([index, p]) => (<MenuItem key={p.id} value={index}>{p.name}</MenuItem>))
                }
                </Select>
            </FormControl> 
            </NoBorderItem>
            </Grid>
            <Grid>
                <TextEditor onChange={setDesc} text={desc} /> 
            </Grid>
            <Grid item xs={12} sm={12}>
                <Box display="flex" justifyContent={"left"}>
                <Button type="submit" variant="outlined" onClick={() => onSubmit()}>Submit </Button>
            </Box>
             
            </Grid>
            <Grid item style={{border:0}} xs={12} sm={12}>
            <UploadImages portfolioId={portfolios[selected].id} />
            </Grid>
            </Grid>
        </>
    )};

export default FillPortfolio;
