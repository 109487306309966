import {MouseEvent, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { useRef, useState } from 'react';

import Box from "@mui/material/Box";
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';

import {DropDownMenu} from "./DropDownMenu";
import {ArrayMenuType} from "./NavigationItems";
import RobEveLogo  from "../images/RobEveLogo.png";

interface FlexibleMenuProps {
    isMobile: boolean;
    menu: ArrayMenuType;
}
const drawerWidth = 240;
 
function FlexibleMenu(props: FlexibleMenuProps) {
    // debugger;
    const [mobileOpen, setMobileOpen] = useState(false);
    const [dropDownOpen, setDropDownOpen] = useState(false);    

    useEffect(() => {
        setMobileOpen(false);
        setDropDownOpen(false);
    }, [props.isMobile, setMobileOpen, setDropDownOpen])

    return (<>
        {props.isMobile && <MobileMenu menu={props.menu} mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} dropDownOpen={dropDownOpen} setDropDownOpen={setDropDownOpen} />}
        {!props.isMobile && <NavMenu menu={props.menu} dropDownOpen={dropDownOpen} setDropDownOpen={setDropDownOpen} />}
    </>);
}

function MobileMenu(props: { menu: ArrayMenuType, mobileOpen: boolean, setMobileOpen: any, dropDownOpen: boolean, setDropDownOpen: any})
{   
    const {menu, mobileOpen, setMobileOpen, dropDownOpen, setDropDownOpen} = props;
    const handleDrawerToggle = (event: MouseEvent) => {
        setMobileOpen(!mobileOpen);
    };
    return (
        <>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2  }}
            >
                <MenuIcon />
            </IconButton>

            <Box component="nav">
                <Drawer
                    // container={container}
                    anchor='right'
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    <Box sx={{ textAlign: 'center' }}>
                        <Box
                            component="img"
                            sx={{width: 100 }}
                            alt="RobEve Logo"
                            src={RobEveLogo}
                        />
    
                    {/* <Typography variant="h6" sx={{ my: 2 }}>
                        RobEve
                    </Typography> */}
                    <Divider />
                    <List>
                        {menu.map((item) => 
                            <MobileMenuItem key={item.name} item={item} mobileOpen={mobileOpen} setDropDownOpen={(open: boolean) => {setDropDownOpen(open); setMobileOpen(open)}} dropDownOpen={dropDownOpen} />
                        )}
                    </List>
                    </Box>
                </Drawer>
            </Box>
        </>    
    );
}

function MobileMenuItem(props: {item: any, mobileOpen: boolean, setDropDownOpen: any, dropDownOpen: boolean}) {
    const {item, mobileOpen, setDropDownOpen, dropDownOpen} = props;
    const currentItemRef = useRef<HTMLDivElement>(null);
    return (<ListItem
        disablePadding>
        <ListItemButton
            sx={{
                textAlign: 'center',
                justifyContent: mobileOpen ? 'initial' : 'center',
            }}
            {...(item.dropdown == null && { component: NavLink })}
            {...(item.dropdown == null && { to: item.path })}
            {...(item.dropdown ? { onClick: () => setDropDownOpen(true) } : { onClick: () => setDropDownOpen(false) })}
            ref={currentItemRef}
        >
            <ListItemText
                primary={item.name} />
        </ListItemButton>
        {item.dropdown && <DropDownMenu
            items={item.dropdown}
            close={(e) => { setDropDownOpen(false); } } opened={dropDownOpen} anchor={currentItemRef}>
        </DropDownMenu>}

    </ListItem>);
}

function NavMenu(props: {menu: ArrayMenuType, dropDownOpen: boolean, setDropDownOpen: any}) {
    const {menu, dropDownOpen, setDropDownOpen} = props;
    return (
        <Box sx={{ display:  'block' }}>
            {menu.map((item) => 
                <NavMenuItem key={item.name} item={item} setDropDownOpen={setDropDownOpen} dropDownOpen={dropDownOpen} />
            )}
        </Box>
    );
}


function NavMenuItem(props: { item: any, setDropDownOpen: any, dropDownOpen: boolean }) {
    const {item, dropDownOpen, setDropDownOpen} = props;
    const currentItemRef = useRef<HTMLButtonElement>(null);
    return <span>
        <Button
            sx={{
                color: '#000',
                '&.active': {
                    fontWeight: "bold"
                }
            }}
            to={item.path}
            {...(item.dropdown == null && { component: NavLink })}
            {...(item.dropdown == null && { to: item.path })}
            {...(item.dropdown ? {
                onClick: () => setDropDownOpen(true)
            } : { onClick: undefined })}
            ref={currentItemRef}
        >
            {item.name}
        </Button>
        {item.dropdown && <DropDownMenu
            items={item.dropdown}
            close={(e) => { setDropDownOpen(false); } } opened={dropDownOpen} anchor={currentItemRef}>
        </DropDownMenu>}

    </span>;
}

export default FlexibleMenu;
