
type MenuType = {
  name: string;
  path?: string;
  dropdown?: Array<MenuType>;
}

type ArrayMenuType = Array<MenuType>;
var mainNavigationItems: ArrayMenuType;

// portfolioNavigationItems = [
//   { name: 'Portretfotografie', path: '/portfolio', dropdown: undefined },
//   { name: 'Familiefotografie en films', path: '/portfolio', dropdown: undefined},
//   { name: 'Evenementen fotografie', path: '/portfolio', dropdown: undefined},
//   { name: 'Bedrijfsfotografie', path: '/portfolio', dropdown: undefined },
//   { name: 'Promofilmpjes', path: '/portfolio', dropdown: undefined }
// ]
mainNavigationItems = [
    { name: 'Home', path: '/', dropdown: undefined },
    { name: 'Portfolio', path: undefined, dropdown: undefined },
    { name: 'About me', path: '/about' , dropdown: undefined },
    { name: 'Contact', path: '/contact', dropdown: undefined }
  ];

export { mainNavigationItems };
export type { ArrayMenuType };






