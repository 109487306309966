import * as React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Fade from '@mui/material/Fade';

import RobEveLogo  from "../images/RobEveLogo.png";
import  FlexibleMenu from './FlexibleMenu';
import { useUserContext } from './useUserContext';
import { Button } from '@mui/material';

interface Props {
  window?: () => Window;
}


export default function RobEveAppBar(props: Props ) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { loggedIn, setLoggedIn, navMenu } = useUserContext();

  function logout() {
    fetch('/api/v1/session', {method: 'DELETE'})
      .then((res) => setLoggedIn(false))
      .catch((err) => alert('unable to logout'));
  }
 
  function ScrollTop(props: Props) {
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 100,
    });
  
    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
      const anchor = (
        (event.target as HTMLDivElement).ownerDocument || document
      ).querySelector('#back-to-top-anchor');
  
      if (anchor) {
        anchor.scrollIntoView({
          block: 'center',
        });
      }
    };
      
    return (
      <Fade in={trigger}>
        <Box
          onClick={handleClick}
          role="presentation"
          sx={{ position: 'fixed', bottom: 16, right: 16 }}
        >
        </Box>
      </Fade>
    );
  }
 
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <AppBar component="nav" color="inherit">
          <Toolbar sx={{justifyContent: 'space-between'}}>
            {/* <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
            > */}
            <Box
              component="img"
              sx={{width: 100 }}
              alt="RobEve Logo"
              src={RobEveLogo}
            />
            { loggedIn ? ( <Box>
            <Button variant="text" onClick={() => logout()} >Logout</Button>
            </Box> ) : "" }
            <Box display="flex" justifyContent="flex-end" alignSelf="flex-end">
              <>
                <FlexibleMenu
                  isMobile={isMobile}
                  menu={navMenu} /> 
              </>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
      <ScrollTop {...props}>
        <Fab size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </>
  );
}
