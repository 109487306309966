import { matchIsValidTel, MuiTelInput } from "mui-tel-input";
import { Controller } from "react-hook-form";
import { FormInputProps } from "./FormInputProps";

function FormInputTel({ name, control, label, errorMessage}: FormInputProps ) {
    return (
      <Controller
        name={name}
        control={control}
        rules={{
          required: { value: true, message: errorMessage },
          validate: { valid: matchIsValidTel }
        }}
        render={({
          field, fieldState: { error },
          formState,
        }) => ( 
        <MuiTelInput
           {...field} 
           langOfCountryName="nl" 
           defaultCountry='NL'
           onlyCountries={['NL', 'BE', 'DE', 'LU']}
           preferredCountries={['NL']}
          //  forceCallingCode
           variant='standard'
           itemType='tel'
           helperText={ error ? ( error.message ? error.message : error.type === "valid" ? "incorrect telefoon nummer" : null) : null }
           label={label}
           error={error !== undefined}
          />
        )}
      />
    )
  };

  export default FormInputTel;
  
  