import * as React from 'react';

import { Box } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import LoginForm from './LoginForm';
import FillPortfolio from './FillPortfolio';
import PortfolioManagement from "./PortfolioManagement";
import useLoginContext from '../components/useUserContext';
import NoBorderItem from "../components/NoBorderItem";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


function Admin() {
    const { loggedIn } = useLoginContext();
    const [value, setValue] = React.useState(0);
    console.log("logged in is: ", loggedIn);    
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

    return (
      <>
      { !loggedIn ? ( 
          <NoBorderItem>
            <LoginForm/>
          </NoBorderItem>
        ) : (
        // Create tabs navigation to distinguish between portfolio management en filling in a portfolio
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example">
                  <Tab label="Portfolio beheer" {...a11yProps(0)} />
                  <Tab label="Portfolio invullen" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <PortfolioManagement/>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <FillPortfolio/>
              </TabPanel>
 
            </Box>
          )
        }                  
      </>
    );
}

export default Admin;  
  
  
  