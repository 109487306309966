import React, { useEffect, useState } from "react";
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult,
    ResponderProvided,
    DraggableProvided,
    DroppableProvided,
    DraggableStateSnapshot
} from "react-beautiful-dnd";
import {
    Button,
    Divider,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";

import ReorderIcon from "@mui/icons-material/Reorder";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

import useUserContext from "../components/useUserContext";
import { PortfolioItem } from "../components/PortfolioItemProps";

/* 
Note: this is a working example, but more can be done to improve it.

In particular, on drag, the table cells in the dragged row may collapse and shrink the overall row.

If you wish to preserve their size mid-drag, you can create a custom component that wraps
the material TableCell and saves the pre-drag dimensions (e.g. in a ref or in state).
The component can be passed an 'isDragging' prop (via snapshot.isDragging) and can conditionally
apply pre-drag width/height via styles.

Pre-drag dimensions can be obtained via the new-ish ResizeObserver API. If you are using class 
components, the getSnapshotBeforeUpdate() lifecycle method can work with getBoundingClientRect(), 
*/



function PortfolioManagement() {
    const { portfolios, refresh } = useUserContext();
     const [portfolioName, setPortFolioName] = useState('');
    const [localItems, setLocalItems] = useState(portfolios);
    const [pending, setPending] = useState(false);
    
    console.log("portfolios:", portfolios)
    console.log("localItems:", localItems)


    function deletePortfolio(id: string) {
        setLocalItems(localItems.filter(portfolioItem => portfolioItem.id !== id ));
        return fetch('/api/v1/portfolio/' + id, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: id
            })
        })
            .then()
            .catch((err) => alert('Kon portfolio niet verwijderen' + err));
    }

    function editPortfolioServer(id: string, name: string, index: number) {
        fetch('/api/v1/portfolio/' + id, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                name: name,
                index: index
            })
        })
            .then(() => { refresh() })
            .catch((err) => alert('Kon portfolio niet wijzigen' + err));
    }

    function savePortfolioServer(name: string) {
        return fetch('/api/v1/portfolio', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                name: name,
                desc: ''
            })
        })
            .then( () => refresh())
            .catch((err) => alert('Kon portfolio niet saven' + err));
    }



    function savePortfolio() {
        console.log(portfolioName);
        // const newPortfolio: PortfolioItem = {id: "temp", name: portfolioName, index: 0, desc: ""}
        // localItems.push(newPortfolio);
        // setLocalItems(localItems)
        savePortfolioServer(portfolioName).then(() => { refresh() });
    }

    // cache the items provided via props in state for purposes of this demo

    useEffect(() => {
        setLocalItems(portfolios);
        // update server stuff here
        if (pending) {
            // do ajax request
            console.log('sending new sequence', { localItems });

            localItems?.map((row: PortfolioItem, index: number) => {
                editPortfolioServer(row.id, row.name, index);
                console.log(row.id, row.name, row.desc, index);
            })

            setPending(false);
        }
    }, [localItems, pending, portfolios]);

    // normally one would commit/save any order changes via an api call here...
    const handleDragEnd = (result: DropResult, provided?: ResponderProvided) => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        setLocalItems((prev: any) => {
            const temp = [...prev];
            const d = temp[result.destination!.index];
            temp[result.destination!.index] = temp[result.source.index];
            temp[result.source.index] = d;

            return temp;
        });
        setPending(true);
    };
    function EditCell({ row, reload }: { row: PortfolioItem, reload: () => void }): JSX.Element {

        function savePortfolio(id: string, name: string, index: number) {
            editPortfolioServer(id, name, index);
        }

        const [editing, setEditing] = useState(false);
        const [portfolioName, setPortFolioName] = useState('');
        return (
            <>
                <TableCell align="left">
                    <TextField
                        InputProps={{ disableUnderline: true }}
                        fullWidth
                        size="medium" variant='standard'
                        disabled={!editing} defaultValue={row.name}
                        required={false}
                        name={row.name}
                        label={""}
                        onChange={(event) => setPortFolioName(event.target.value)} />
                </TableCell>
                <TableCell align="left" onClick={() => setEditing(true)}><EditIcon color="primary" /></TableCell>
                <TableCell align="left" {...(!editing && {
                    onClick: () => {
                        deletePortfolio(row.id).then(() => { reload() });
                    }
                })}
                >
                    {editing ? (<DeleteIcon color="disabled" />) : (<DeleteIcon color="primary" />)}
                </TableCell>
                <TableCell align="left" {...(editing && {
                    onClick: () => {
                        savePortfolio(row.id, portfolioName, row.index);
                        setEditing(false);
                        reload();
                    }
                })}>
                    {editing && <SaveIcon color="primary" />}
                </TableCell>
            </>
        );
    }
    return (
        <>
            <Typography variant="h4">
                Portfolio beheer
            </Typography>
            <br />
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        size="medium" variant='outlined'
                        placeholder="portfolio naam"
                        onChange={(event) => setPortFolioName(event.target.value)} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Button variant="contained" color="primary" onClick={() => {
                        savePortfolio()
                    }} >
                        Voeg portfolio toe
                        <AddIcon />
                    </Button>
                </Grid>
            </Grid>
            <Divider variant="fullWidth" />
            <TableContainer>
                <Table>
                    <TableHead>
                    </TableHead>
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="droppable" direction="vertical">
                            {(droppableProvided: DroppableProvided) => (
                                <TableBody
                                    ref={droppableProvided.innerRef}
                                    {...droppableProvided.droppableProps}
                                >
                                    {localItems?.map((row: PortfolioItem, index: number) => (
                                        <Draggable
                                            key={row.id}
                                            draggableId={row.id}
                                            index={index}
                                        >
                                            {(
                                                draggableProvided: DraggableProvided,
                                                snapshot: DraggableStateSnapshot
                                            ) => {
                                                return (
                                                    <TableRow
                                                        ref={draggableProvided.innerRef}
                                                        {...draggableProvided.draggableProps}
                                                        style={{
                                                            ...draggableProvided.draggableProps.style,
                                                            background: snapshot.isDragging
                                                                ? "rgba(245,245,245, 0.75)"
                                                                : "none"
                                                        }}
                                                    >
                                                        {/* note: `snapshot.isDragging` is useful to style or modify behaviour of dragged cells */}
                                                        <TableCell align="left">
                                                            <div {...draggableProvided.dragHandleProps}>
                                                                <ReorderIcon />
                                                            </div>
                                                        </TableCell>
                                                        <EditCell key={row.id} row={row} reload={() => refresh()} />
                                                    </TableRow>
                                                );
                                            }}
                                        </Draggable>
                                    ))}
                                    {droppableProvided.placeholder}
                                </TableBody>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Table>
            </TableContainer>
        </>
    );
};




export default PortfolioManagement;
