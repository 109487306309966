import { useLocation } from "react-router-dom";

import { Typography } from "@mui/material";
import { responsiveFontSizes, ThemeProvider, useTheme} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import NoBorderItem from "../components/NoBorderItem";
import PortfolioImageList from "../components/PortfolioImageList";
import useUserContext from "../components/useUserContext";
import { useEffect, useState } from "react";


interface StateProps {
    name: string;
}

interface ImageData{
    id: string;
    portfolio: string;
}

function Portfolio() {
    let theme = useTheme();
    theme = responsiveFontSizes(theme);
    const state = useLocation().state as StateProps;
    const { portfolios } = useUserContext();
    const portfolio = portfolios.find(obj => { return obj.name === state.name; });
    var description : string = "";
    const [ urlImages, setUrlImages ] = useState<ImageData[]>( []);
    if ( portfolio === undefined) {
        description = "Geen omschrijving";
    } else {
        console.log("Portfolio:", portfolio.name);
        description = portfolio.desc;
    }
   
    // Fetch urls from server for images
    useEffect(() => {
        if (portfolio !== undefined) {
            console.log("refresh: get url images");
            fetch('/api/v1/portfolio/' + portfolio.id + '/image',
                { method: 'GET' })
                .then(res => res.json())
                .then(data => {
                    console.log("data:", data);
                    setUrlImages(data);
                })
                .catch((err) => alert('unable to get list of images for portfolio: ' + err));
        } else {
            console.log("refresh: portfolio undefined");
        }
    }, [portfolio, setUrlImages]);

    console.log("images url:", urlImages);
    const urls = urlImages.map(image => `images/${image.id}.jpeg`);
    console.log( "urls:", urls);
    return (
        <>
        <ThemeProvider theme={theme}>
        <Grid container spacing={2}>
            <Grid item style={{border:0}} xs={12} sm={12}>
                <NoBorderItem>
                    <Typography variant="h2" align="left">
                     {state.name}
                    </Typography>                        
                </NoBorderItem>  
            </Grid>              
            <Grid item style={{border:0}} xs={12} sm={12}>
                <NoBorderItem>
                    <div dangerouslySetInnerHTML={{ __html: description }}>
                    </div>
                </NoBorderItem>
            </Grid>
            <Grid item xs={12} sm={12}>
                <NoBorderItem>
                    <PortfolioImageList images={urls} />
                 </NoBorderItem>
             </Grid>
            </Grid>
            </ThemeProvider>
        </>
    );
}

export default Portfolio;