import { Typography } from "@mui/material";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Photographer  from "../images/ProfilePhoto.jpg";
import RobEveLogo    from "../images/RobEveLogo.png";
import NoBorderItem from "../components/NoBorderItem";

function About() {
    return (
        <>
            <Grid container spacing={2}>
                <Grid item style={{ border: 0 }} xs={12} sm={6}>
                </Grid> 
                <Grid item style={{border:0}} xs={12} sm={6}>
                    <NoBorderItem>
                        <Typography variant="h6" align="left">
                        </Typography>                        
                    </NoBorderItem>  
                </Grid>              
                <Grid item style={{border:0}} xs={12} sm={6}>
                    <NoBorderItem>
                        <>
                        <Box
                            component="img"
                            sx={{width: '60%'}}
                            alt="RobEve Logo"
                            src={RobEveLogo}
                        />
                        <div>
                        <Box
                            component="img"
                            sx={{width: '60%'}}
                            alt="Robert Milewski"
                            src={Photographer}
                        />
                        </div>
                        </>
                    </NoBorderItem>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <NoBorderItem>
                        <Typography variant="h6" align="left">
                            <br /><br />
                            Ik ben Robert Milewski, geboren in 1959 in Polen. Sinds 1981 leef en werk ik in Nederland.
                            <br/><br/><br/>
                        Ooit een warm gevoel van blijdschap en voldoening gehad? Dat voel ik elke keer als een foto precies lukt zoals ik het wilde. Fantastisch gevoel. Fotograferen is voor mij een uitlaatklep geworden. En als ik daarbij mensen blij kan maken met een mooi plaatje voelt dat als een cadeautje.<br/><br/>
Mensen fotograferen vind ik prachtig. Dat kan door middel van een mooi portret in de studio of in hun eigen omgeving, ongedwongen en dicht bij zichzelf. Of door middel van een reportage, in bijzondere situaties of in het dagelijks leven met zijn ups en downs. Het vastleggen van emoties, het persoonlijke verhaal erachter ontdekken, de invloed van verleden en heden op het doen en laten van iemand optekenen en met mooie beelden vertellen of dat ene speciale moment vangen, dat zijn mijn drijfveren om fotograaf te worden. 
<br/><br/>
Hoewel mijn eerste prioriteit bij portret- en reportage fotografie ligt, maak ik ook graag productfoto’s en sfeeropnames. Hierin kan ik mijn creativiteit tot uiting laten komen en een product in beeld brengen op een manier die aandacht trekt en intrigeert. 
<br /><br />
Ik ben een rustig persoon, maar wel flexibel, vriendelijk en makkelijk in de omgang. Ik kan mensen met wie ik werk op hun gemak stellen. Hoewel ik in mijn werk de wereld, mensen en producten wil laten zien zoals ze in werkelijkheid zijn, probeer ik dit altijd via mijn eigen “point of view” te doen.

                   </Typography>
                    </NoBorderItem>
                </Grid>
            </Grid>
          </>
    
    )
};

export default About;
