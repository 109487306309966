import { useState, useRef } from "react";

import { useForm } from "react-hook-form";
import Reaptcha from 'reaptcha';

import Grid from '@mui/material/Grid';
import { Box, Button, Typography } from "@mui/material";

import FormInputText from "../components/FormInputText";
import FormPassword from "../components/FormPassword";
import { useUserContext } from "../components/useUserContext";

interface IFormInput {
    user: string;
    password: string;
}
  
const defaultValues = {
    user: "",
    password: "",
};
  
const SITEKEY = "6LfeJ5kiAAAAAB4T-Mk2_yJ6eindMQVsv6cL-Bg1";

function LoginForm() {
    const methods = useForm<IFormInput>({ mode: "onSubmit",  reValidateMode: "onSubmit", defaultValues: defaultValues });
    const { handleSubmit, reset, control  } = methods;

    const [captchaToken, setCaptchaToken] = useState(false);
    const captchaRef = useRef<Reaptcha>(null);
    const { loggedIn, setLoggedIn} = useUserContext();

    const verify = () =>{
        setCaptchaToken(true);
    }
  
    function onSubmit(data: IFormInput) {
      fetch('/api/v1/session', { 
        method: 'POST', 
        headers: { 'Content-Type': 'application/json'}, 
        body: JSON.stringify({
          name: data.user,
          pass: data.password,
        })
      })
      .then((res) => { if(res.status === 200) { setLoggedIn(true) } else { alert('incorrect login') } })
      .catch((err) => alert('error' + err));

      console.log(data, loggedIn); 
      reset(defaultValues);
      captchaRef?.current?.reset();
       
      // Validate on server username and password
      // After successfull validation
      // show admin menu? Portfolio?
      // Redirect to other page
      // Show logged in status in navbar via Context and/or logout button

      // 1. Set login context to true to show login status in navbar
      
    }
  
    return (
      <>
        <form onSubmit={handleSubmit(onSubmit)}> 
        <Typography variant="h6" align="left" gutterBottom>
          Login
          </Typography>

        <Grid container spacing={3}>
   
          <Grid item xs={12} sm={12}>
            <FormInputText name="user" control={control} label="Gebruiker*" errorMessage="Gebruiker mag niet leeg zijn"/>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormPassword name="password" control={control} label="wachtwoord*" errorMessage="Wachtwoord mag niet leeg zijn"/>          
          </Grid>
  
          <Grid item xs={12}>
            <Box display="flex" justifyContent={"left"}>
              <Reaptcha sitekey={SITEKEY} onVerify={verify} ref={captchaRef} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent={"left"}>
              <Button disabled={!captchaToken} type="submit" variant="outlined">Submit</Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent={"left"}>
              <Typography variant="body2" align="left" gutterBottom>
                * verplichte velden
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </form>
      </>
    );
  }

  export default LoginForm;