import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contact';
import About from './pages/About';
import Admin from './pages/Admin';
import Portfolio from './pages/Portfolio';

const AppRouter = () => {
return (         
    <Routes>
    <Route path='/' element ={<Home/>} />
    <Route path='/contact' element={<Contact/>} />
    <Route path='/about' element={<About/>} />    
    <Route path='/admin' element={<Admin/>} />  
    <Route path='/portfolio' element={<Portfolio/>} />  
  </Routes>
);
}
export default AppRouter;