import { useState, useRef } from "react";

import { useForm, Controller } from "react-hook-form";
import Reaptcha from 'reaptcha';
import Grid from '@mui/material/Grid';
import { Box, Button, Typography } from "@mui/material";
import TextField from '@mui/material/TextField';

import FormInputText from "../components/FormInputText";
import FormInputTel from "../components/FormInputTel";
import FormCheckbox from "../components/FormCheckbox";
import FormFeedback from "../components/FormFeedback";


const SITEKEY = "6LfeJ5kiAAAAAB4T-Mk2_yJ6eindMQVsv6cL-Bg1";

interface IFormInput {
    voornaam: string;
    achternaam: string;
    adres: string;
    postcode: string;
    plaats: string;
    land: string;
    email: string,
    telefoon: string,
    opmerkingen: string,
    toestemming: boolean;
    submitted: string;
  }
  
  const defaultValues = {
    voornaam: "",
    achternaam: "",
    adres: "",
    postcode: "",
    plaats: "",
    land: "",
    email: "",
    telefoon: "",
    opmerkingen: "",
    toestemming: false,
    submitted: ""
  };
  
function ContactForm() {
    const methods = useForm<IFormInput>({ mode: "onSubmit",  reValidateMode: "onSubmit", defaultValues: defaultValues });
    const { handleSubmit, reset, control  } = methods;
    const [submitMessage, setSubmitMessage ] = useState('');
    const captchaRef = useRef<Reaptcha>(null);


    const [captchaToken, setCaptchaToken] = useState(false);
    
    const verify = () =>{
        setCaptchaToken(true);
    }
  
    function onSubmit(data: IFormInput) {
      console.log(data);
      fetch('/api/v1/contact', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data)
      })
      .then((res) => {
        setSubmitMessage("Hartelijk dank voor Uw vraag, ik neem zo spoedig mogelijk contact met U op.")
        reset(defaultValues);
        captchaRef?.current?.reset();
      })
      .catch((err) => {
        alert('Er ging iest mis met het contactformulier, probere het later opnieuw');
        console.error(err);
      })

    }
  
    return (
      <>
        <form onSubmit={handleSubmit(onSubmit)}> 
        <Typography variant="body1" align="left" gutterBottom>
        Wil je meer informatie over mij, mijn diensten en tarieven, wil je weten hoe ik te werk ga of heb je specifieke vragen, 
        neem dan contact met mij op of vul het onderstaand contactformulier in. Ik zal zo snel mogelijk reageren.
          </Typography>
        <Grid container spacing={3}>
   
          <Grid item xs={12} sm={6}>
            <FormInputText name="voornaam" control={control} label="Voornaam*" errorMessage="Voornaam mag niet leeg zijn"/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInputText name="achternaam" control={control} label="Achternaam*" errorMessage="Achternaam mag niet leeg zijn"/>          
          </Grid>
          <Grid item xs={12}>
            <FormInputText name="adres" control={control} label="Adres*" errorMessage="Adres mag niet leeg zijn"/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInputText name="plaats" control={control} label="Stad*" errorMessage="Stad mag niet leeg zijn"/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInputText name="postcode" control={control} label="Postcode*" errorMessage="Postcode mag niet leeg zijn"/>          
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInputText name="land" control={control} label="Land" required={false} errorMessage="Land mag niet leeg zijn"/>          
          </Grid>
          <Grid item xs={12} sm={6}>
          <Box display="flex" justifyContent={"left"}>
            <FormInputTel
                name="telefoon" 
                control={control}
                label="Telefoon*"
                errorMessage="Telefoonnummer mag niet leeg zijn"
              />
           </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInputText name="email" control={control} label="email*" errorMessage="Email mag niet leeg zijn" />          
          </Grid>
  
          <Grid item xs={12}>
            <Box display="flex" flexDirection={'column'} justifyContent={"left"}>
              <FormCheckbox name="toestemming" control={control} label="Ik ga akkoord dat mijn gegevens worden opgeslagen" errorMessage="U dient akkoord te gaan" />
            </Box>
           </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent={"left"}>
              <Controller
                 name="opmerkingen"
                 control={control}
                 render={({ field }) => (
                  <TextField
                    {...field}
                    multiline
                    minRows={2}
                    maxRows={4}
                    label="opmerking/vraag"
                    type="text"
                    variant='outlined'
                    fullWidth
                  />
                 )}
              />
            </Box>
          </Grid>
  
          <Grid item xs={12}>
            <Box display="flex" justifyContent={"left"}>
              <Reaptcha sitekey={SITEKEY} ref={captchaRef} onVerify={verify} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent={"left"}>
              <Button disabled={!captchaToken} type="submit" variant="outlined">Submit</Button>
            </Box>
          </Grid>
 
                    
          <Grid item xs={12}>
            <Box display="flex" justifyContent={"left"}>
              <Typography variant="body2" align="left" gutterBottom>
                * verplichte velden
              </Typography>
            </Box>
          </Grid>
  
          <Grid item xs={12}>
            <Box display="flex" justifyContent={"left"}>
              <FormFeedback name="submitted" control={control} label={submitMessage} errorMessage={""} />
             </Box>
          </Grid>
  
        </Grid>
      </form>
      </>
    );
  }
  
export default ContactForm;  
  
  
  