import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { FormInputProps } from "./FormInputProps";

export const FormPassword = ({ name, control, label, errorMessage, required } : FormInputProps) => {
  return (
    <Controller
        name={name}
        control={control}
        rules={{
            required: { value: required === undefined ? true : required, message: errorMessage }
        }}
        render={({
            field, fieldState: { error },
            formState,
          }) => (
            <TextField
              {...field}
              type="password"
              helperText={error ? error.message : null }
              size="small"
              error={!!error}
              fullWidth
              label={label}
              variant="standard"
            />
      )}
    />
  );
};

export default FormPassword;