import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { FormInputProps } from "./FormInputProps";

export const FormInputText = ({ name, control, label, errorMessage, required, disabled, defaultValue } : FormInputProps) => {
  return (
    <Controller
        name={name}
        control={control}
        rules={{
            required: { value: required === undefined ? true : required, message: errorMessage }
        }}
        render={({
            field, fieldState: { error },
            formState,
          }) => (
            <TextField
              {...field}
              helperText={error ? error.message : null }
              size="small"
              error={!!error}
              fullWidth
              label={label}
              variant="standard"
              disabled={disabled}
              defaultValue={defaultValue}
            />
      )}
    />
  );
};

export default FormInputText;