import { Controller, useFormState } from "react-hook-form";
import {  Typography } from "@mui/material";

import { FormInputProps } from "./FormInputProps";

function FormFeedback({ name, control, label }: FormInputProps ) {            
  const { isDirty } = useFormState({control}); 
  return (
  <Controller
    name={name}
    control={control}
    render={() => {
      return (
        <Typography color="green" variant="h6" align="left" gutterBottom>
          { isDirty ? "" : label }
        </Typography>
      )
    }}
    />
  )
}

export default FormFeedback;
