

import { Typography } from "@mui/material";
import Grid from '@mui/material/Grid';
import MailIcon from '@mui/icons-material/Mail';
import IconButton from '@mui/material/IconButton';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import InstagramIcon from '@mui/icons-material/Instagram';

import NoBorderItem from "../components/NoBorderItem";
import ContactForm from "./ContactForm";

function Contact() {
    return (
        <>
        <Grid container spacing={2}>
        <Grid item style={{border:0}} xs={12} sm={6}>
            <NoBorderItem>
                <Typography variant="h5" align="left">
                CONTACT
                </Typography>
                <br/>
                <Typography variant="body1" align="left">
                Robert Milewski<br/>
                H. Scheepstrahof 14<br/>
                1321 KG Almere<br/> 
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    sx={{ mr: 2  }}
                 >
                     <MailIcon />
                 </IconButton>
                 <a href="mailto:robevefotografie@gmail.com">robevefotografie@gmail.com</a>
                 <br/>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    sx={{ mr: 2  }}
                 >
                <PhoneIphoneIcon />
                </IconButton>
                0628210827
                <br />
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    sx={{ mr: 2 }}
                >
                    <InstagramIcon />
                </IconButton>
                <a href="https://www.instagram.com/robeve_fotografie/">instagram</a>                
                </Typography>  
            </NoBorderItem>  
        </Grid>  
         <Grid item style={{border:0}} xs={12} sm={6}>
            <NoBorderItem>
              <ContactForm/>
            </NoBorderItem>
        </Grid>
    </Grid>
</>
    )
 }

export default Contact;




