import AppRouter from './AppRouter';
import RobEveAppBar from './components/RobEveAppBar';
import { Outlet } from 'react-router-dom';
import { createTheme, ThemeProvider, Toolbar } from '@mui/material';
import { UserContextProvider } from './components/UserContext';
import { grey, red } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    background: {
      default: grey[100],
      paper: grey[100],
    }
  },
});


function App() {
  return (
    <div style={{background: theme.palette.background.default, margin: 0, minHeight: '100vh'}}>
      <ThemeProvider theme={theme}>
        <UserContextProvider>
          <RobEveAppBar />
          <Toolbar id="back-to-top-anchor" />
          <AppRouter />
          <Outlet />
        </UserContextProvider>
      </ThemeProvider>
      </div>
  );
}

export default App;
