import * as React from 'react';
import { ArrayMenuType } from './NavigationItems';
import {Menu, MenuItem} from '@mui/material';
import { NavLink } from "react-router-dom";

// let anchorElement: (arg0: EventTarget & HTMLAnchorElement) => void;
// let buttonElement: (arg0: EventTarget & HTMLButtonElement) => void;


interface DropDownProps {
  items?: ArrayMenuType;
  children: React.ReactNode;
  opened: boolean;
  close: (event?: React.MouseEvent) => void; 
  anchor: React.MutableRefObject<Element | null>;
}
const DropDownMenu = (props: DropDownProps)  => {
      return (
        <Menu
          id="basic-menu"
          anchorEl={props.anchor?.current}
          open={props.opened}
          onClose={(e) => props.close()}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
        {props.items !== undefined &&  (
          props.items.map((item,index) => (
                  <MenuItem 
                    key={index} 
                    onClick={(e) => props.close(e)}
                    component={NavLink}
                    to={{ pathname: item.path }}
                    state={{name: item.name}} 
                  >
                    {item.name}
                  </MenuItem>
          )))}
         </Menu>
    );
}

// const dropdownHandleClickButton = (event: React.MouseEvent<HTMLButtonElement>) => {
//     buttonElement(event.currentTarget);
// }
// const dropdownHandleClickAnchor = (event: React.MouseEvent<HTMLAnchorElement>) => {
//   anchorElement(event.currentTarget);
// }

export { DropDownMenu };
// export { dropdownHandleClickButton, dropdownHandleClickAnchor, DropDownMenu };
  

