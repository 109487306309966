import { Controller } from "react-hook-form";

import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from '@mui/material/Checkbox';

import { FormInputProps } from "./FormInputProps";

function FormCheckbox({ name, control, label, errorMessage}: FormInputProps ) {
  return (
    <Controller
        name={name}
        control={control}
        rules={{ required: errorMessage }}
        render={({ field, fieldState: {error}}) => (
        <>
            <FormControl
                required
                component="fieldset"
                error={true}
                variant="standard"
            >
                <FormGroup>
                <FormControlLabel {...field}
                control={<Checkbox checked={field.value} inputProps={{type: 'checkbox'}} color="primary" />}
                label={label}
                labelPlacement='end'
                />
                </FormGroup>
                <FormHelperText>{error ? error.message : null }</FormHelperText>
            </FormControl>  
        </> 
    )}
    />
  );
}      

export default FormCheckbox;