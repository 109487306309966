import { ReactNode } from "react";

import { Typography } from "@mui/material";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import IconButton from '@mui/material/IconButton';
import Photographer  from "../images/ProfilePhoto.jpg";
import RobEveLogo    from "../images/RobEveLogo.png";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

function Home() {
    const NoBorder = ({ children } : { children: ReactNode }) => {
        return <Paper style={{textAlign: 'center'}} elevation={0}>{children}</Paper>
    }
    return (
        <>
          {/* <Box sx={{ flexGrow: 1, border:0 }}> */}
            <Grid container spacing={2}>
                <Grid item style={{border:0}} xs={12} sm={6}>
                    <NoBorder>
                        <>
                        <Box
                            component="img"
                            sx={{width: '60%'}}
                            alt="RobEve Logo"
                            src={RobEveLogo}
                        />
                        <div>
                        <Box
                            component="img"
                            sx={{width: '60%'}}
                            alt="Robert Milewski"
                            src={Photographer}
                        />
                        </div>
                        </>
                    </NoBorder>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <NoBorder>
                        <Typography variant="h6" align="left">
                            <br/>
                        Hi! Welkom bij RobEve Fotografie!
                        Ik ben Robert Milewski, fotograaf gevestigd in Almere.
                        Vertrouwd adres voor alle mooie momenten die je vast wilt leggen.
                        <br/>
                        <br/>
                        Even voorstellen:
                        <br />
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/U8yePWDNxes"
                                title="YouTube video player" frameBorder="0"
                                allowFullScreen>
                            </iframe>
                        </Typography>
                        <br/><br/>
                        <Typography variant="body2" align="left" gutterBottom>
                        Nieuwsgierig geworden? Bekijk mijn site of neem contact op!
                        <br/>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            sx={{ mr: 2  }}
                        >
                            <MailIcon />
                        </IconButton>
                        robevefotografie@gmail.com
                        <br/>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            sx={{ mr: 2  }}
                        >
                            <PhoneIphoneIcon />
                        </IconButton>
                        0628210827
                            {/* <br />
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                sx={{ mr: 2 }}
                            >
                                <FacebookIcon />
                            </IconButton>
                            <a href="https://www.facebook.com/profile.php?id=100087600223710">facebook</a> */}
                            <br />
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                sx={{ mr: 2 }}
                            >
                                <InstagramIcon />
                            </IconButton>
                            <a href="https://www.instagram.com/robeve_fotografie/">instagram</a>
                        </Typography>
                    </NoBorder>
                </Grid>
            </Grid>
          {/* </Box> */}
          </>
    
    )
};

export default Home;
